<template>
  <v-container class="pa-0 fill-height" fluid>
    <!-- Snackbar para errores -->
    <SnackbarMessage ref="snackbar" />

    <!-- Layout de carga -->
    <LoadingLayout v-if="isLoading || isUploadingSale || !searchClient">
      <CircularLoading>
        {{ isUploadingSale ? "Guardando venta" : "" }}
      </CircularLoading>
    </LoadingLayout>
    <!-- Contenido principal -->
    <v-container class="fill-height" v-else fluid>
      <!-- Fila para el contenido principal -->
      <v-row no-gutters>
        <!-- Columna para el buscador de productos -->
        <v-col cols="12" md="8" class="mb-4">
          <!-- Fila para la barra de búsquedas -->
          <v-row no-gutters class="deltia-card mr-md-2 pa-3 pb-0">
            <!-- Columna para el título -->
            <v-col cols="12">
              <p class="text-h5 mb-0 font-weight-bold">
                Buscador de productos
              </p>
              <!-- Placeholder para buscar los productos -->
              <p class="text--secondary" v-if="branches.length > 1">
                Selecciona una sucursal antes de buscar productos
              </p>
            </v-col>
            <!-- Buscador de productos -->
            <v-col cols="12" v-if="searchClient">
              <v-text-field
                @input="textFieldChange"
                label="Busca en tus productos"
                prepend-inner-icon="mdi-magnify"
                color="secondary"
                filled
                rounded
                clearable
              />
            </v-col>
            <!-- Fila para mostrar los resultados -->
            <v-col cols="12" justify="center">
              <v-row
                v-for="hit in searchResults"
                :key="hit.objectID"
                no-gutters
                class="deltia-card mb-2 pl-1 pr-1"
                align="center"
              >
                <!-- Columna para la imagen del producto -->
                <v-col cols="1">
                  <v-img rounded :src="hit.image" :aspect-ratio="1" contain />
                </v-col>
                <!-- Columna para el nombre del producto -->
                <v-col cols="9" style="background-color: bl">
                  <p class="font-weight-bold ma-0">{{ hit.name }}</p>
                  <p class="ma-0">{{ hit.description }}</p>
                </v-col>
                <!-- Columna para agregar el producto -->
                <v-col cols="2" align="right">
                  <v-btn color="primary" @click="addProductToSale(hit)" icon>
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Fila para los productos agregados -->
          <v-row no-gutters class="mr-md-2 pa-3 pb-0">
            <!-- Productos seleccionados -->
            <v-col cols="12" v-if="selectedProducts.length > 0">
              <!-- Titulo -->
              <p class="text-h5 mb-0 font-weight-bold">
                Productos seleccionados
              </p>
              <!-- Cada item -->
              <v-row
                class="deltia-card pt-2 pb-2 mb-2"
                v-for="item in selectedProducts"
                :key="item.productID"
              >
                <!-- Imagen -->
                <v-col cols="2" md="1" class="pa-0 ma-0">
                  <v-img rounded aspect-ratio="1" :src="item.image" contain />
                </v-col>
                <!-- Descripción del producto -->
                <v-col cols="10" md="6" class="pl-2 pr-1">
                  <v-row no-gutters>
                    <!-- Columna para el nombre del producto -->
                    <v-col cols="12">
                      <p class="font-weight-bold mb-0">
                        {{ item.name }} (${{ item.sale_price }})
                      </p>
                    </v-col>
                    <!-- Columna para la descripción del producto -->
                    <v-col cols="12">
                      <p class="font-weight-regular mb-0">
                        {{ item.description }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Text Field para agregar la cantidad de cada producto -->
                <v-col cols="12" md="5" class="mt-3 mt-md-0">
                  <v-text-field
                    v-model="item.quantity"
                    :rules="[rules.strictlyPositive]"
                    class="mt-0 mb-0"
                    placeholder="0"
                    color="secondary"
                    label="Cantidad"
                    hide-details="auto"
                    rounded
                    filled
                    dense
                    min="1"
                    type="number"
                  />
                </v-col>
                <!-- Sección para el descuento -->
                <v-col cols="12">
                  <v-btn
                    class="text--disabled mt-1 mb-1 pl-0 pr-0"
                    text
                    @click="item.showDiscount = !item.showDiscount"
                  >
                    Agregar descuento
                    <v-icon v-if="item.showDiscount">mdi-menu-down</v-icon>
                    <v-icon v-else>mdi-menu-right</v-icon>
                  </v-btn>
                </v-col>
                <!-- Campos de texto -->
                <v-col cols="12" v-if="item.showDiscount">
                  <v-row no-gutters>
                    <!-- Descuento (cantidad) -->
                    <v-col cols="6" class="pr-1">
                      <v-text-field
                        v-model="item.discountNumber"
                        @input="value => onDiscountNumberChange(item, value)"
                        :rules="[rules.positive]"
                        type="number"
                        color="secondary"
                        label="Neto"
                        hide-details="auto"
                        rounded
                        filled
                        dense
                        placeholder="0"
                      />
                    </v-col>
                    <!-- Descuento (porcentaje) -->
                    <v-col cols="6" class="pl-1">
                      <v-text-field
                        v-model="item.discountPercentage"
                        @input="value => onDiscuntPercentgeChange(item, value)"
                        :rules="[rules.percentageLowerThan100, rules.positive]"
                        type="number"
                        color="secondary"
                        label="Porcentaje"
                        placeholder="0"
                        hide-details="auto"
                        rounded
                        filled
                        dense
                        suffix="%"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Botón para remover el producto -->
                <v-col cols="12" class="text-center pt-1 pb-1">
                  <v-btn color="red" text @click="removeProductFromSale(item)">
                    Remover de la venta
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- Columna para los datos de la venta -->
        <v-col cols="12" md="4">
          <v-row no-gutters class="deltia-card ml-md-2 pa-4">
            <!-- Columna para el título -->
            <v-col cols="12">
              <p class="text-h5 font-weight-bold">
                Nueva Venta
              </p>
            </v-col>
            <!-- Sucursal de la venta -->
            <v-col cols="12">
              <p class="mb-0 font-weight-medium text--disabled">
                Sucursal de la venta
              </p>
              <v-select
                v-model="currentBranch"
                @change="searchResults = transformItems(allSearchResults)"
                :items="branches"
                label="Agrega la sucursal de venta"
                no-data-text="No hay sucursales disponibles"
                color="secondary"
                item-color="secondary"
                item-text="name"
                item-value="_id"
                filled
                rounded
                dense
              ></v-select>
            </v-col>
            <!-- Mesa -->
            <v-col v-if="isRestaurant" cols="12">
              <p class="mb-0 font-weight-medium text--disabled">
                Mesa
              </p>
              <v-select
                v-model="selectedTable"
                :items="tables"
                label="Agrega la mesa de la venta"
                no-data-text="No hay mesas disponibles"
                color="secondary"
                item-color="secondary"
                item-text="name"
                item-value="_id"
                filled
                rounded
                dense
              ></v-select>
            </v-col>
            <!-- Elegir cliente -->
            <v-col cols="12" class="mb-0 pb-0">
              <p class="mb-0 font-weight-medium text--disabled">
                Cliente
              </p>
              <v-select
                filled
                rounded
                dense
                return-object
                v-model="customer"
                label="Agregar un cliente"
                color="secondary"
                no-data-text="No hay clientes registrados"
                :items="customers"
                item-text="full_name"
                item-value="_id"
                class="mb-0 pb-0"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <!-- Botón para agregar un nuevo cliente -->
            <v-col cols="12" class="pt-0 mt-0">
              <NewClient
                :showPrimaryButton="false"
                @new-client="customerCreated"
                @client-error="showError"
              ></NewClient>
            </v-col>
            <!-- Estado de la venta -->
            <v-col cols="12">
              <p class="mb-0 font-weight-medium text--disabled">
                Estado de la venta
              </p>
              <v-select
                v-model="currentStatus"
                :items="getStasuses(getProfile.business_id)"
                label="Selecciona el estado de tu venta"
                color="secondary"
                item-color="secondary"
                item-text="name"
                item-value="type"
                filled
                rounded
                dense
              ></v-select>
            </v-col>
            <!-- Método de pago -->
            <v-col cols="12">
              <p class="mb-0 font-weight-medium text--disabled">
                Método de pago
              </p>
              <v-select
                v-model="paymentMethod"
                :items="getPaymentMethods()"
                label="Selecciona el método de pago"
                color="secondary"
                item-color="secondary"
                item-text="displayName"
                item-value="key"
                filled
                rounded
                dense
              />
            </v-col>
            <!-- Monto adicional -->
            <v-col cols="12">
              <p class="mb-0 font-weight-bold text--disabled">
                Monto adicional
              </p>
              <v-text-field
                v-model="additionalAmount"
                :rules="[rules.positive]"
                placeholder="0"
                color="secondary"
                filled
                rounded
                min="0"
                type="number"
                prefix="$"
              />
            </v-col>
            <!-- Personas en la mesa -->
            <v-col cols="12" v-if="selectedTable">
              <p class="mb-0 font-weight-bold text--disabled">
                Personas en la mesa
              </p>
              <v-text-field
                v-if="isRestaurant"
                v-model="tablePeopleCount"
                placeholder=""
                color="secondary"
                filled
                rounded
                min="0"
                type="number"
              />
            </v-col>
            <!-- Total de la venta -->
            <v-col cols="12">
              <p class="mb-0 font-weight-bold text--disabled">
                Total
              </p>
              <p class="font-weight-bold">
                {{ `$${this.saleTotalPrice}` }}
              </p>
            </v-col>
            <!-- Columna para guardar la venta -->
            <v-col cols="12">
              <PrimaryButton @click="createSale">Completar venta</PrimaryButton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
// importar componentes
import LoadingLayout from "@/layouts/LoadingLayout";
import CircularLoading from "@/components/Loading/Circular";
import PrimaryButton from "@/components/PrimaryButton";
import SnackbarMessage from "@/components/SnackbarMessage";
import NewClient from "@/components/clients/NewClient";

// Importar servicios
import BranchesServices from "@/services/BranchesServices";
import MovementsServices from "@/services/MovementsServices";
import CustomersServices from "@/services/CustomersServices";
import TablesServices from "@/services/TablesServices";

// Importar dependencias
import { mapGetters } from "vuex";

// Importar variables globales
import algoliasearch from "algoliasearch";

// Importar mixins
import salesStatuses from "@/mixins/salesStatuses";
import paymentMethods from "@/mixins/paymentMethods";

export default {
  name: "CreateSale",
  mixins: [salesStatuses, paymentMethods],
  components: {
    LoadingLayout,
    CircularLoading,
    PrimaryButton,
    SnackbarMessage,
    NewClient
  },
  data() {
    return {
      branches: [],
      customer: null,
      customers: [],
      tables: [],
      allSearchResults: [],
      searchResults: [],
      isLoading: false,
      isUploadingSale: false,
      searchClient: null,
      currentStatus: "completed",
      additionalAmount: "",
      currentBranch: "",
      selectedTable: "",
      paymentMethod: "cash",
      tablePeopleCount: undefined,
      selectedProducts: [],
      rules: {
        positive: value =>
          value >= 0 ? true : "Este campo no puede ser negativo",
        strictlyPositive: value =>
          value > 0 ? true : "Este campo no puede ser negativo o cero",
        percentageLowerThan100: value =>
          value <= 100 ? true : "El descuento no puede ser mayor al 100%"
      }
    };
  },
  created: async function() {
    // Mostrar pantalla de carga
    this.isLoading = true;

    try {
      // Obtener sucursales y public key de Algolia
      const {
        branches,
        algoliaPublicKey
      } = await BranchesServices.getUserBranchesWithAlgoliaPublicKey();

      // Guardar sucursales
      this.branches = branches;

      // Obtener clientes
      await this.getCustomers();

      // Obtener mesas
      await this.getTables();

      // Inicializar Algolia
      this.searchClient = algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        algoliaPublicKey
      );
      this.productsIndex = this.searchClient.initIndex("products");

      // Seleccionar la única sucursal si sólo hay una
      if (this.branches.length === 1) {
        this.currentBranch = this.branches[0]._id;
      }

      // Ocultar pantalla de carga
      this.isLoading = false;
    } catch (err) {
      // Mostrar error
      this.showError(err.message);
    }
  },
  methods: {
    getCustomers: async function() {
      try {
        // Realizar petición
        const customers = await CustomersServices.getCustomersByBusinessId();
        // Guardar los clientes
        this.customers = customers.docs;
      } catch (err) {
        // Mostrar error
        this.showError(err.message);
      }
    },
    getTables: async function() {
      try {
        // Realizar petición
        const tables = await TablesServices.getTablesByBusiness();
        // Guardar los clientes
        this.tables = tables.docs;
      } catch (err) {
        // Mostrar error
        this.showError(err.message);
      }
    },
    transformItems: function(items) {
      // Aplanamos el objeto de productos seleccionados en la venta
      const selectedProductsIds = this.selectedProducts.map(
        product => product.objectID
      );

      const filteredProducts = items.filter(
        item =>
          item.branches.includes(this.currentBranch) &&
          !selectedProductsIds.includes(item.objectID)
      );

      return filteredProducts.map(item => {
        // Cortar nombre si es demasiado largo
        if (item.name.length > 100) {
          item.name = item.name.slice(0, 100) + "...";
        }

        // Cortar descripción si es demasiado larga
        if (item.description.length > 100) {
          item.description = item.description.slice(0, 100) + "...";
        }

        return item;
      });
    },
    createSale: async function() {
      // Verificar si hay productos seleccionados
      if (this.selectedProducts.length === 0) {
        // Emitir mensaje de error
        this.showError("Selecciona al menos un producto");
        return;
      }

      // Verificar si se rellenaron todas las cantidades
      if (!this.areQuantitiesComplete) {
        // Emitir mensaje de error
        this.showError("Rellena todas las cantidades");
        return;
      }

      // Verificar si hay cantidades negativas
      if (this.areThereNegativeQuantities) {
        // Emitir mensaje de error
        this.showError("No puede haber cantidades negativas");
        return;
      }

      // Mostrar pantalla de carga
      this.isUploadingSale = true;

      // Almacenar respuesta
      let didCreateSale = false;

      try {
        // Crear venta
        const sale = await MovementsServices.createSale({
          selectedProducts: this.selectedProducts,
          branchId: this.currentBranch,
          customerId: this.customer ? this.customer._id : undefined,
          additionalAmount: this.additionalAmount
            ? parseFloat(this.additionalAmount)
            : 0,
          currentStatus: this.currentStatus,
          tableId: this.selectedTable,
          tablePeopleCount: this.tablePeopleCount,
          paymentMethod: this.paymentMethod
        });

        // Enviar evento a mixpanel
        this.$mixpanel.track("new-sale", {
          distinct_id: this.getProfile.business_id,
          total: sale.total,
          status: sale.status
        });

        didCreateSale = true;
      } catch (err) {
        // Ocultar pantalla de carga
        this.isUploadingSale = false;

        // Emitir evento con el error
        this.showError(err.message);
        return;
      }

      // Verificar si se creó la venta
      if (didCreateSale) {
        // Actualizar ventas y limpiar venta
        this.reset();
        this.$router.push({ name: "sales" });
      }
    },
    reset: function() {
      if (this.branches.length > 1) {
        this.currentBranch = "";
      }

      // Vaciamos los campos
      this.currentProduct = {};
      this.selectedProducts = [];
      this.currentStatus = "completed";
    },
    addProductToSale: async function(product) {
      // Agregar producto
      this.selectedProducts.push({
        ...product,
        quantity: 1,
        showDiscount: false
      });
      this.searchResults = this.transformItems(this.allSearchResults);
    },
    removeProductFromSale: function(product) {
      const index = this.selectedProducts.indexOf(product);
      this.selectedProducts.splice(index, 1);

      this.searchResults = this.transformItems(this.allSearchResults);
    },
    showError(errorMessage) {
      // Mostrar error
      this.$refs.snackbar.showError(errorMessage);
    },
    customerCreated: async function(customer) {
      // Obtener clientes
      await this.getCustomers();

      // Seleccionar el consumidor creado
      this.customer = customer.data;

      this.$refs.snackbar.showSuccessMessage("Cliente registrado con éxito");
    },
    onDiscountNumberChange(item, value) {
      // Calcular porcentaje
      item.discountPercentage =
        (value / (item.sale_price * item.quantity)) * 100;
    },
    onDiscuntPercentgeChange(item, value) {
      // Calcular valor numérico
      item.discountNumber = ((value * item.sale_price) / 100) * item.quantity;
    },
    textFieldChange: async function(searchValue) {
      // Buscar datos con Algolia
      const searchResults = await this.productsIndex.search(searchValue);
      this.allSearchResults = searchResults.hits;
      this.searchResults = this.transformItems(this.allSearchResults);
    }
  },
  computed: {
    ...mapGetters(["getProfile", "getBusiness"]),
    saleTotalPrice: function() {
      // Definir reducer para la suma de los precios
      const reducer = (accumulator, product) => {
        // Obtener cantidad del producto
        const quantity = product.quantity ? product.quantity : 0;

        // Supar precio del producto
        accumulator += product.sale_price * quantity;

        // Restar descuento
        const discount = product.discountNumber ? product.discountNumber : 0;
        accumulator -= discount;

        // Esta línea es sólo para que el precio se recompute cuando se actualice el porcentaje
        product.discountPercentage;

        return accumulator;
      };

      const additionalAmount = this.additionalAmount
        ? parseFloat(this.additionalAmount)
        : 0;

      // Regresar suma de los productos
      return this.selectedProducts.reduce(reducer, 0) + additionalAmount;
    },
    areQuantitiesComplete: function() {
      // Iterar por los productos seleccionados
      for (let i = 0; i < this.selectedProducts.length; i += 1) {
        const product = this.selectedProducts[i];

        // Verificar si no existe la cantidad
        if (!product.quantity) {
          return false;
        }
      }

      return true;
    },
    areThereNegativeQuantities: function() {
      // Iterar por los productos seleccionados
      for (let i = 0; i < this.selectedProducts.length; i += 1) {
        const product = this.selectedProducts[i];

        // Verificar si la cantidad es negativa
        if (product.quantity < 0) {
          return true;
        }
      }

      return false;
    },
    isRestaurant() {
      return this.getBusiness.sector === "restaurant";
    }
  }
};
</script>
